import { useState } from 'react';

import { Segment, Grid, Card, Container, Header, Message,Icon, Statistic } from 'semantic-ui-react';
import './StatCard.css'

const stats = [

]

export const StatCard = (props) => {

  function onTrigger (chartID) {
        console.log(chartID);
        props.pushItem(chartID);
    };

  const cardName = props.cardName;

  return (
    <Segment className="card-segment">

    <Grid>
    <Grid.Row>
    <Grid.Column width={14} style={{padding:"0em"}}>
    <Header as='h5'>

      <Header.Content>
           {cardName}
           <Header.Subheader>2023</Header.Subheader>
       </Header.Content>
       </Header>
       </Grid.Column>
      <Grid.Column width={2} style={{textAlign:"right"}}>

    </Grid.Column>
    </Grid.Row>
    </Grid>
    <div className="card-container">
    <Grid >
    <Grid.Row>
      <div >
        <p className="card-stat">$1,000,000</p>
        <p className="card-stat-label">Fees YTD</p>
      </div>
    </Grid.Row>
    <Grid.Row>
      <div>
      <p className="card-stat">($200,000)</p>
      <p className="card-stat-label">YTD Fee Reduction</p>
      </div>
    </Grid.Row>

    </Grid>
    </div>

    </Segment>

  );

};
