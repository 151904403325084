import {
  BrowserRouter,
  Routes,
  Route,
  Link,
} from "react-router-dom";

import { Home } from './pages/home/home'
import { Dashboard } from './pages/dashboard/dashboard'
import { Summary } from "./pages/summary/Summary";
import { Detail } from "./pages/detail/Detail";
import { FrontGraphic } from "./components/FrontGraphic/FrontGraphic";

export const BaseRouter = (props) => (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/demo*" element={<Dashboard />} />
      <Route path="/deer" element={<FrontGraphic />} />

    </Routes>
);

export const GeminiRouter = (props) => (

  <Routes>

      <Route path="/*" element={<Summary />} />
      <Route path=":reportpage*" element={<Summary />} />
      <Route path=":reportpage/:id*" element={<Detail />} />
  </Routes>

);
