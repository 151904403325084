export const BarData = (
[
  {
    "country": "AD",
    "hot dog": 75,
    "hot dogColor": "hsl(12, 70%, 50%)",
    "burger": 38,
    "burgerColor": "hsl(100, 70%, 50%)",
    "sandwich": 126,
    "sandwichColor": "hsl(63, 70%, 50%)",
    "kebab": 20,
    "kebabColor": "hsl(322, 70%, 50%)",
    "fries": 86,
    "friesColor": "hsl(219, 70%, 50%)",
    "donut": 19,
    "donutColor": "hsl(33, 70%, 50%)"
  },
  {
    "country": "AE",
    "hot dog": 60,
    "hot dogColor": "hsl(111, 70%, 50%)",
    "burger": 153,
    "burgerColor": "hsl(173, 70%, 50%)",
    "sandwich": 38,
    "sandwichColor": "hsl(131, 70%, 50%)",
    "kebab": 105,
    "kebabColor": "hsl(59, 70%, 50%)",
    "fries": 110,
    "friesColor": "hsl(42, 70%, 50%)",
    "donut": 144,
    "donutColor": "hsl(114, 70%, 50%)"
  },
  {
    "country": "AF",
    "hot dog": 75,
    "hot dogColor": "hsl(172, 70%, 50%)",
    "burger": 62,
    "burgerColor": "hsl(161, 70%, 50%)",
    "sandwich": 135,
    "sandwichColor": "hsl(58, 70%, 50%)",
    "kebab": 176,
    "kebabColor": "hsl(299, 70%, 50%)",
    "fries": 144,
    "friesColor": "hsl(225, 70%, 50%)",
    "donut": 102,
    "donutColor": "hsl(293, 70%, 50%)"
  },
  {
    "country": "AG",
    "hot dog": 31,
    "hot dogColor": "hsl(290, 70%, 50%)",
    "burger": 104,
    "burgerColor": "hsl(54, 70%, 50%)",
    "sandwich": 37,
    "sandwichColor": "hsl(131, 70%, 50%)",
    "kebab": 97,
    "kebabColor": "hsl(358, 70%, 50%)",
    "fries": 159,
    "friesColor": "hsl(96, 70%, 50%)",
    "donut": 189,
    "donutColor": "hsl(113, 70%, 50%)"
  },
  {
    "country": "AI",
    "hot dog": 50,
    "hot dogColor": "hsl(9, 70%, 50%)",
    "burger": 1,
    "burgerColor": "hsl(294, 70%, 50%)",
    "sandwich": 127,
    "sandwichColor": "hsl(43, 70%, 50%)",
    "kebab": 196,
    "kebabColor": "hsl(334, 70%, 50%)",
    "fries": 80,
    "friesColor": "hsl(52, 70%, 50%)",
    "donut": 176,
    "donutColor": "hsl(267, 70%, 50%)"
  },
  {
    "country": "AL",
    "hot dog": 91,
    "hot dogColor": "hsl(259, 70%, 50%)",
    "burger": 184,
    "burgerColor": "hsl(236, 70%, 50%)",
    "sandwich": 164,
    "sandwichColor": "hsl(182, 70%, 50%)",
    "kebab": 27,
    "kebabColor": "hsl(105, 70%, 50%)",
    "fries": 58,
    "friesColor": "hsl(240, 70%, 50%)",
    "donut": 55,
    "donutColor": "hsl(317, 70%, 50%)"
  },
  {
    "country": "AM",
    "hot dog": 0,
    "hot dogColor": "hsl(206, 70%, 50%)",
    "burger": 75,
    "burgerColor": "hsl(17, 70%, 50%)",
    "sandwich": 27,
    "sandwichColor": "hsl(30, 70%, 50%)",
    "kebab": 193,
    "kebabColor": "hsl(25, 70%, 50%)",
    "fries": 123,
    "friesColor": "hsl(22, 70%, 50%)",
    "donut": 155,
    "donutColor": "hsl(40, 70%, 50%)"
  }
]
)

export const PieData = (
[
  {
    "id": "CBOE",
    "label": "CBOE",
    "value": 411,
    "color": "hsl(111, 70%, 50%)"
  },
  {
    "id": "NASDAQ",
    "label": "NASDAQ",
    "value": 391,
    "color": "hsl(30, 70%, 50%)"
  },
  {
    "id": "NYSE",
    "label": "NYSE",
    "value": 576,
    "color": "hsl(257, 70%, 50%)"
  },
  {
    "id": "MIAX",
    "label": "MIAX",
    "value": 346,
    "color": "hsl(148, 70%, 50%)"
  },

]
)

export const LineData = (
[
  {
    "id": "2022",
    "color": "hsl(249, 70%, 50%)",
    "data": [
      {
        "x": "Jan",
        "y": 112
      },
      {
        "x": "Feb",
        "y": 93
      },
      {
        "x": "Mar",
        "y": 251
      },
      {
        "x": "Apr",
        "y": 198
      },
      {
        "x": "May",
        "y": 140
      },
      {
        "x": "Jun",
        "y": 99
      },
      {
        "x": "Jul",
        "y": 127
      },
      {
        "x": "Aug",
        "y": 66
      },
      {
        "x": "Sep",
        "y": 284
      },
      {
        "x": "Oct",
        "y": 195
      },
      {
        "x": "Nov",
        "y": 59
      },
      {
        "x": "Dec",
        "y": 100
      }
    ]
  },
  {
    "id": "2023",
    "color": "hsl(117, 70%, 50%)",
    "data": [
      {
        "x": "Jan",
        "y": 247
      },
      {
        "x": "Feb",
        "y": 215
      },
      {
        "x": "Mar",
        "y": 86
      },
      {
        "x": "Apr",
        "y": 45
      },
      {
        "x": "May",
        "y": 296
      },
      {
        "x": "Jun",
        "y": 67
      },
      {
        "x": "Jul",
        "y": 270
      },
      {
        "x": "Aug",
        "y": 223
      },
      {
        "x": "Sep",
        "y": 43
      },
      {
        "x": "Oct",
        "y": 215
      },
      {
        "x": "Nov",
        "y": 27
      },
      {
        "x": "Dec",
        "y": 197
      }
    ]
  }
]
)

export const BumpData = (

[
  {
    "id": "NASDAQ",
    "data": [
      {
        "x": 'Jan',
        "y": 11
      },
      {
        "x": 'Feb',
        "y": 13
      },
      {
        "x": 'Mar',
        "y": 25
      },

    ]
  },
  {
    "id": "NYSE",
    "data": [
      {
        "x": 'Jan',
        "y": 29
      },
      {
        "x": 'Feb',
        "y": 23
      },
      {
        "x": 'Mar',
        "y": 15
      },

    ]
  },
  {
    "id": "MIAX",
    "data": [
      {
        "x": 'Jan',
        "y": 8
      },
      {
        "x": 'Feb',
        "y": 22
      },
      {
        "x": 'Mar',
        "y": 23
      },

    ]
  },
  {
    "id": "CBOE",
    "data": [
      {
        "x": 'Jan',
        "y": 30
      },
      {
        "x": 'Feb',
        "y": 21
      },
      {
        "x": 'Mar',
        "y": 10
      },

    ]
  },

]
)

export const StreamData = (

[
  {
    "Raoul": 57,
    "Josiane": 80,
    "Marcel": 49,
    "René": 200,
    "Paul": 153,
    "Jacques": 81
  },
  {
    "Raoul": 152,
    "Josiane": 145,
    "Marcel": 117,
    "René": 72,
    "Paul": 108,
    "Jacques": 14
  },
  {
    "Raoul": 112,
    "Josiane": 158,
    "Marcel": 26,
    "René": 113,
    "Paul": 167,
    "Jacques": 187
  },
  {
    "Raoul": 123,
    "Josiane": 18,
    "Marcel": 142,
    "René": 143,
    "Paul": 181,
    "Jacques": 116
  },
  {
    "Raoul": 34,
    "Josiane": 151,
    "Marcel": 140,
    "René": 127,
    "Paul": 189,
    "Jacques": 102
  },
  {
    "Raoul": 71,
    "Josiane": 23,
    "Marcel": 173,
    "René": 145,
    "Paul": 84,
    "Jacques": 150
  },
  {
    "Raoul": 166,
    "Josiane": 102,
    "Marcel": 153,
    "René": 41,
    "Paul": 144,
    "Jacques": 143
  },
  {
    "Raoul": 20,
    "Josiane": 191,
    "Marcel": 149,
    "René": 31,
    "Paul": 165,
    "Jacques": 192
  },
  {
    "Raoul": 175,
    "Josiane": 196,
    "Marcel": 90,
    "René": 112,
    "Paul": 116,
    "Jacques": 52
  }
]
)
