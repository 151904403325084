const localhost = "http://127.0.0.1:8000";

const apiURL = "/api";

export const endpoint = `${localhost}${apiURL}`;

export const productListURL = `${endpoint}/products/`;
export const tradeDataURL = `${endpoint}/tradedata/`;
export const reportListURL = `${endpoint}/reports/`;
export const productDetailURL = id => `${endpoint}/products/${id}/`;
