import { useState,useEffect } from "react";

import { Segment,
         Grid,
         Card,
         Container,
         Header,
         Message,
         Icon
} from 'semantic-ui-react';

import { DataTable } from "../../components/DataTable/DataTable";
import { BarChart, PieChart, LineChart } from "../../containers/Charts";
import {BarData, PieData, LineData} from "./data";

export function Detail (props) {
  const [activeMenu, setActiveMenu] = useState('Summary')
  const [data, setData] = useState({})

  const showDetails = (id, value) => {
        setData(
          {
          id:id,
          value:value,}
        );
          console.log(id,value);
      };

  return(
    <Grid>
      <Grid.Row>
        <Grid.Column >

          <Segment className="chart-segment">
          <Grid>
          <Grid.Row>
             <Grid.Column width={14} >
          <Header as='h5'>
             <Icon circular size='tiny' inverted floated='left' name='chart line'/>
            <Header.Content>

                 <Header.Subheader>2019</Header.Subheader>
             </Header.Content>
             </Header>
             </Grid.Column>
            <Grid.Column width={2} style={{textAlign:"right"}}>
            <Icon name='download' onClick={()=>props.history.push(`/demo/${activeMenu}/`)}/>
          <Icon name='close' onClick={()=>props.history.push(`/demo/${activeMenu}/`)}/>
          </Grid.Column>
          </Grid.Row>
          </Grid>
          <div style={{height: "300px"}}>
          <BarChart
            data={BarData}
            keys={[ 'AMEX', 'ARCA', 'PHLX', 'CBOE' ]}
            index={"month"}
            groupMode={"stacked"}
            layout={"vertical"}
            pushDetails={showDetails}
          />
          </div>

          </Segment>



        </Grid.Column>
        </Grid.Row>
        <Grid.Row>
        <Grid.Column>

        <DataTable/>


        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}
