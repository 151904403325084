import logo from './logo.svg';
import './App.css';
import 'semantic-ui-css/semantic.min.css'
import './styles/_common.css'

import {BaseRouter} from "./routes";

import {
  BrowserRouter,
} from "react-router-dom";

import { createMedia } from '@artsy/fresnel'

export const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
})

export const MainContainer = ({ children }) => {
  return <div className="main-container">{children}</div>;
};

function App() {
  return (
    <MediaContextProvider>
    <BrowserRouter>
    <MainContainer>
      <BaseRouter />
      </MainContainer>
    </BrowserRouter>
    </MediaContextProvider>
  );
}

export default App;
