import { useState,useCallback } from "react";
import {
  Button,
  Container,
  Grid,
  Header,
  Segment,
  Icon,
  Image,
  List,
  Divider,
  Menu,
  Card,
  Label
} from "semantic-ui-react";
import {TopNav} from "../../components/dashboardNav/dashboardNav"
import "./dashboard.css"
import {GeminiRouter} from "../../routes";

import {
  Link,
} from "react-router-dom";

const reports = [
      {
        name: 'summary',
        icon: 'inbox',
        link: 'summary'
      },
      {
        name: 'exchange fees',
        icon: 'checkmark box',
        link: 'exchange'
      },
      {
        name: 'broker attribution',
        icon: 'sitemap',
        link: 'broker'
      },

    ];

export function Dashboard (props) {
  const [activeMenu, setActiveMenu] = useState('summary')
  return(
    <div>
    <TopNav/>
    <Segment className="dashboard-segment">
    <Grid>

    <Grid.Row columns={2}>
      <Grid.Column width={3}>
        <Menu vertical fluid borderless className="dashboard-menu">
        {reports.map(item => {
          return (
                <Link to={`/demo/${item.link}`} name={item.name} key={item.name}
                  active={activeMenu === item.name ? true:false}
                  onClick={() => setActiveMenu(item.name)}
                  >

                  <Menu.Item active={activeMenu === item.name}>
                  {item.name}

                  </Menu.Item>
                </Link>
              )
          })}
          </Menu>
      </Grid.Column>
      <Grid.Column width={13}>
      <Grid.Row>
        <Grid.Column>
          <Header>
          Dashboard
          </Header>
        </Grid.Column>
      </Grid.Row>
          <GeminiRouter/>
      </Grid.Column>
    </Grid.Row>
    </Grid>
    </Segment>
    </div>
  )


}
