import { React, useState,useCallback } from "react";
import {
  Button,
  Container,
  Grid,
  Header,
  Segment,
  Icon,
  Image,
  List,
  Divider,
  Menu,
  Card
} from "semantic-ui-react";
import "./topNav.css"
import {
  Link,
} from "react-router-dom";
import { Media } from '../../App'


export const TopNav = (props) => {


    return(
          <>
          {props.mobile ? (
            <Menu
              fixed = 'top'
              className="top-nav-menu"
              borderless
              text
            >
            <Container>
            <Menu.Item position='right' onClick={props.handleToggle}>
              <Icon size='large' name='sidebar' inverted/>
            </Menu.Item>
            </Container>
            </Menu>
          ):(
            <Menu
              fixed = {props.fixed ? 'top' : null}
              secondary
              className="top-nav-menu"
              borderless
              text
            >
            <Container>
          <Menu.Item as={Link} fitted to="/dashboard" className="top-nav-menu-item">
            About
          </Menu.Item>
          <Menu.Item position='right' fitted>
            <Link to="/demo">
              <Button inverted basic className="top-nav-menu-button" >
                Demo
              </Button>
            </Link>
          </Menu.Item>
          </Container>
          </Menu>
          )}
          </>
    )
}
