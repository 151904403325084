import { useState,useCallback } from "react";
import {
  Button,
  Container,
  Grid,
  Header,
  Segment,
  Icon,
  Image,
  List,
  Divider,
  Menu,
  Card
} from "semantic-ui-react";
import "./dashboardNav.css"
import {
  Link,
} from "react-router-dom";

export const TopNav = ({ mobile }) => {

    const [fixed, setFixed] = useState(true);

    return(

      <Menu
        fixed
        secondary
        className="top-nav-menu"
      >
        <Container className="top-nav-container">

          <Menu.Item as={Link} to="/dashboard" className="top-nav-menu-item">

            Pellicer Financial

          </Menu.Item>

          <Menu.Item position='right'>
          <Link to="/">
            <Button inverted basic className="top-nav-menu-button" >
              Logout
            </Button>
            </Link>
          </Menu.Item>
        </Container>
      </Menu>

    )
}
