import { useState,useCallback,useRef, useEffect } from "react";
import "./FrontGraphic.css"

import {
  Button,
  Container,
  Grid,
  Header,
  Segment,
  Icon,
  Image,
  Divider,
  Menu,
  Card,
  List
} from "semantic-ui-react";

import Particles from "react-particles";
import { loadFull } from "tsparticles";

import type { ParticlesContainer, Engine } from "tsparticles-engine";
import {particlesConfig,particlesConfig2,particlesConfig3} from "../../particles";
import { Parallax, ParallaxLayer } from '@react-spring/parallax'
import { MainTitle } from "../MainTitle/MainTitle";
import { MainSegment, BottomSegment } from "../MainSegment/MainSegment";
import { TopNav } from "../topNav/topNav";

import vector from "../../media/img/vector3.png"


export function FrontGraphic (props){
  const [position1, setPosition1] = useState(0);

  const particlesInit = useCallback(async engine => {
   console.log(engine);
   await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async container => {
   await console.log(container);
  }, []);

  const parallax = useRef()

  const handleScroll = () => {
    if (parallax.current) {

      if (parallax.current.current > 350 && parallax.current.current < 1050){
        setPosition1(true)
      }
      else{
        setPosition1(false)
      }


    }
  }

  useEffect(() => {
    const container = document.querySelector('.my-class-name')
    container.addEventListener('scroll', handleScroll)
    return () => {
      container.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    console.log(position1)
  }, [position1])

  const url = (name: string, wrap = false) =>
    `${wrap ? 'url(' : ''}https://awv3node-homepage.surge.sh/build/assets/${name}.svg${wrap ? ')' : ''}`

  return(


    <div className="background-container">

        <Parallax ref={parallax} pages={3} innerStyle={{ backgroundColor: '#03041A' }} className='my-class-name'>

        <ParallaxLayer ref={parallax} offset={0} speed={1} >
        <div className="scroll-segment-top">
        </div>
        </ParallaxLayer>
        <ParallaxLayer offset={1} speed={1} >
        <div className="scroll-segment-middle">
        </div>
        </ParallaxLayer>
        <ParallaxLayer offset={2} speed={1} style={{ backgroundColor: '#03041A' }} />

        <ParallaxLayer
         offset={0}
         speed={0}
         factor={3}
         style={{
           backgroundImage: url('stars', true),
           backgroundSize: 'cover',
         }}
       />

       <ParallaxLayer
        offset={2.5}
        speed={-0.4}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          pointerEvents: 'none',

        }}>
        <Image src={vector} />
      </ParallaxLayer>


      <ParallaxLayer
          offset={2}
          speed={-0.3}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'left',
          }}
        >
      </ParallaxLayer>
      <ParallaxLayer
         offset={0}
         speed={0.1}
         onClick={() => parallax.current.scrollTo(1)}
         style={{
           display: 'flex',
           alignItems: 'center',
           justifyContent: 'center',
         }}>

         <MainTitle/>
       </ParallaxLayer>

       <ParallaxLayer
        offset={1}
        speed={0.1}
        onClick={() => parallax.current.scrollTo(2)}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>

        <MainSegment active={position1}/>
      </ParallaxLayer>
      <ParallaxLayer
        offset={2}
        speed={-0}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onClick={() => parallax.current.scrollTo(0)}>
        <BottomSegment/>
      </ParallaxLayer>
     </Parallax>
    </div>

  )

}
