import { useState,useCallback, useEffect } from "react";

import { Segment,
         Grid,
         Card,
         Container,
         Header,
         Message,
         Icon
} from 'semantic-ui-react';

import { useNavigate } from "react-router-dom";

import { StatCard } from "../../components/StatCard/StatCard";
import { ActionCard } from "../../components/ActionCard/ActionCard";
import { ChartCard } from "../../components/ChartCard/ChartCard";
import {BarData, PieData, LineData, BumpData} from "./data";

import axios from "axios";
import { reportListURL} from "../../constants";
import { flatten } from 'lodash';

export function Summary (props) {
  const [activeMenu, setActiveMenu] = useState('Summary')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [reports, setReports] = useState([])
  const [reportModules, setReportModules] = useState([])

  let navigate = useNavigate();

  const fetchReports =()=>{
    axios
      .get(reportListURL)
      .then(res => {
        setReports(res.data);
        setLoading(false);

      })
      .catch(err => {
        setLoading(false);
        setError(err);
      });
  };

  useEffect(() => {


    console.log(activeMenu)
    setReportModules(getModules(reports,activeMenu))
  },[reports]);

  useEffect(() => {

    fetchReports();
  },[activeMenu]);

  const getModules = (array,val) =>{
    const filteredReport = array.filter(item => val === item.name).map((item,i) => item.modules);
    const modules = flatten(filteredReport);
    console.log(modules)
    return modules;
  }

  const pushItemCallback = (chartID,chartName) => {
    navigate(`/demo/${activeMenu}/${chartID}`,
            {chartID: chartID,
            chartName: chartName},
    )
  }

  return(
    <Grid>
    <Grid.Row>
    <Grid.Column width={4}>
      <StatCard cardName="MTD Stats"/>
      </Grid.Column>
      <Grid.Column width={12}>
            {reportModules.length>0 && (
            <ChartCard
            key={reportModules[0].id}
            chartType={reportModules[0].chart_type}
            dataSource= {reportModules[0].data_source}
            chartName={reportModules[0].name}
            chartID={reportModules[0].id}
            chartData = {LineData}
            pushItem={pushItemCallback}
            />
            )}
      </Grid.Column>
    </Grid.Row>
    <Grid.Row columns={4}>
      <Grid.Column>
      <ActionCard cardText='Performance' cardColor='dark'/>
      </Grid.Column>
      <Grid.Column>
      <ActionCard cardText='History' cardColor='dark'/>
      </Grid.Column>
      <Grid.Column width={8}>
      <ActionCard
      cardText='Manage Communications'
      cardSubText='Email preferences'/>
      </Grid.Column>
    </Grid.Row>
    <Grid.Row columns={2}>
      <Grid.Column>
        <ChartCard
          chartType='pie'
          chartName='Fees by Exchange'
          chartData = {PieData}
          pushItem={pushItemCallback}
          chartID='1002'
        />
      </Grid.Column>
      <Grid.Column>
      {reportModules.length>0 && (
      <ChartCard
      key={reportModules[1].id}
      chartType={reportModules[1].chart_type}
      dataSource= {reportModules[1].data_source}
      chartName={reportModules[1].name}
      chartID={reportModules[1].id}
      chartData = {BumpData}
      pushItem={pushItemCallback}
      />
      )}
      </Grid.Column>
    </Grid.Row>
    </Grid>
  )
}
