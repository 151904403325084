import React, { Component } from "react";
import { ResponsiveBar } from '@nivo/bar'
import { ResponsivePie } from '@nivo/pie'
import { ResponsiveLine } from '@nivo/line'
import { ResponsiveAreaBump } from '@nivo/bump'
import { ResponsiveStream } from '@nivo/stream'


// Nivo theming
const theme = {
  axis: {
    ticks: {
      line: {
        stroke: "#e9ecee",
        strokeWidth: 0
      },
      text: {
        fill: "#919eab",
        fontFamily: "Nunito"
      }
    }
  },
  grid: {
    line: {
      stroke: "#e9ecee",
      strokeWidth: 1
    }
  },
  legends: {
    text: {
      fontFamily: "Nunito"
    }
  }
};

const getChart = (chartType, data, theme) => {
    console.log(chartType)
};



export class BarChart extends React.Component {
  onTrigger (id, value) {
        this.props.pushDetails(id, value);
    };

  render() {
    const { data, keys, index, groupMode, colors, tickValues, colorBy, customTooltip, layout } = this.props;

    return (
      <ResponsiveBar

          data={data}
          onClick={(data) => {
            this.onTrigger(data["id"], data['value']);

          }}
          keys={keys}
          indexBy={index}
          groupMode={groupMode}
          margin={{
            top: 36,
            right: 32,
            bottom: 36,
            left: 32
          }}
          layout={layout}
          padding={0.5}
            colors={{ scheme: 'blues' }}

          borderColor="#919eab"
          axisBottom={{
            orient: "bottom",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "",
            legendPosition: "middle",
            legendOffset: 36
          }}
          axisLeft={{
            tickValues: tickValues,
            orient: "left",
            tickPadding: 0,
            tickRotation: 0,
            legend: "",
            legendPosition: "start",
            legendOffset: -40
          }}
          enableGridY={true}

          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor="#fff"
          enableLabel={false}
          animate={true}
          motionStiffness={90}
          motionDamping={15}

          role="application"
          ariaLabel="Nivo bar chart demo"
          barAriaLabel={function(e){return e.id+": "+e.formattedValue+" in country: "+e.indexValue}}
        // legends={[
        //   {
        //     dataFrom: "keys",
        //     anchor: "top-left",
        //     direction: "row",
        //     justify: false,
        //     translateX: -10,
        //     translateY: -30,
        //     itemsSpacing: 32,
        //     itemWidth: 100,
        //     itemHeight: 20,
        //     itemDirection: "left-to-right",
        //     itemOpacity: 0.85,
        //     itemTextColor: "#919eab",
        //     symbolSize: 10,
        //     symbolShape: "circle",
        //     effects: [
        //       {
        //         on: "hover",
        //         style: {
        //           itemOpacity: 1
        //         }
        //       }
        //     ]
        //   }
        // ]}
        />

    );
  }
}


export class PieChart extends React.Component {

  onTrigger (id, value) {
        this.props.pushDetails(id, value);
    };

  render() {
    const { data, keys, index, groupMode, colors, tickValues, colorBy, customTooltip, layout } = this.props;

    return (
      <ResponsivePie
        data={data}
        onClick={(data) => {
          this.onTrigger(data["id"], data["value"]);
        }}
        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
        innerRadius={0.5}
        padAngle={2}
        cornerRadius={6}
        colors={{ scheme: 'purple_red' }}
        borderWidth={1}
        borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
        radialLabelsSkipAngle={10}
        radialLabelsTextXOffset={6}
        radialLabelsTextColor="#333333"
        radialLabelsLinkOffset={0}
        radialLabelsLinkDiagonalLength={16}
        radialLabelsLinkHorizontalLength={24}
        radialLabelsLinkStrokeWidth={1}
        radialLabelsLinkColor={{ from: 'color' }}
        slicesLabelsSkipAngle={10}
        slicesLabelsTextColor="#333333"
        animate={true}
        motionStiffness={90}
        motionDamping={15}

        legends={[
            {
                anchor: 'bottom',
                direction: 'row',
                translateY: 56,
                itemWidth: 100,
                itemHeight: 18,
                itemTextColor: '#999',
                symbolSize: 18,
                symbolShape: 'circle',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemTextColor: '#000'
                        }
                    }
                ]
            }
        ]}
    />
  );
  }
  }

export class LineChart extends React.Component {
  onTrigger (id, value) {
        this.props.pushDetails(id, value);
    };

  render() {
    const { data, keys, index, groupMode, colors, tickValues, colorBy, customTooltip, layout } = this.props;

    return (
      <ResponsiveLine
      data={data}
      onClick={(data) => {
        this.onTrigger(data["id"], data['data']['x']);
      }}
      margin={{ top: 30, right: 30, bottom: 30, left: 30 }}
      xScale={{ type: 'point' }}
      yScale={{ type: 'linear', min: '0', max: 'auto', stacked: true, reverse: false }}
      yFormat=" >-$.2f"
      curve="cardinal"
      axisTop={null}
      axisRight={null}
      axisBottom={{
          orient: 'bottom',
          tickSize: 0,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Month',
          legendOffset: 36,
          legendPosition: 'middle'
      }}
      axisLeft={{
          orient: 'left',
          tickSize: 0,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'count',
          legendOffset: -40,
          legendPosition: 'middle'
      }}
      enableGridX={false}
      colors={{ scheme: 'blues' }}
      lineWidth={4}
      theme={theme}
      pointLabelYOffset={-12}
      enableArea={false}
      areaOpacity={1}
      useMesh={true}

  />

);
}
}

export class BumpChart extends React.Component {
    onTrigger (id, value) {
          this.props.pushDetails(id, value);
      };

    render() {
      const { data, keys, index, groupMode, colors, tickValues, colorBy, customTooltip, layout } = this.props;

      return (
        <ResponsiveAreaBump
           data={data}
           onClick={(data) => {
             this.onTrigger(data["id"], data['data']['x']);
           }}
           margin={{ top: 40, right: 100, bottom: 40, left: 100 }}
           spacing={10}
           colors={{ scheme: 'purple_red' }}
           blendMode="multiply"

           borderWidth={3}
           startLabel="id"
           axisTop={{
               tickSize: 5,
               tickPadding: 5,
               tickRotation: 0,
               legend: '',
               legendPosition: 'middle',
               legendOffset: -36
           }}
           axisBottom={{
               tickSize: 5,
               tickPadding: 5,
               tickRotation: 0,
               legend: '',
               legendPosition: 'middle',
               legendOffset: 32
           }}
         />

);
}
}


export class StreamChart extends React.Component {
    onTrigger (id, value) {
    this.props.pushDetails(id, value);
    };

    render() {
     const { data, keys, index, groupMode, colors, tickValues, colorBy, customTooltip, layout } = this.props;

     return (
       <ResponsiveStream
        data={data}
        onClick={(data) => {
          this.onTrigger(data["id"], data['data']['x']);
        }}
        keys={[ 'Raoul', 'Josiane', 'Marcel', 'René', 'Paul', 'Jacques' ]}
        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: '',
            legendOffset: 36
        }}
        axisLeft={{ orient: 'left', tickSize: 5, tickPadding: 5, tickRotation: 0, legend: '', legendOffset: -40 }}
        offsetType="silhouette"
        colors={{ scheme: 'blues' }}
        fillOpacity={0.85}
        borderWidth={5}
        borderColor={{ theme: 'background' }}

        dotSize={8}
        dotColor={{ from: 'color' }}
        dotBorderWidth={2}
        dotBorderColor={{ from: 'color', modifiers: [ [ 'darker', 0.7 ] ] }}
        legends={[
            {
                anchor: 'bottom-right',
                direction: 'column',
                translateX: 100,
                itemWidth: 80,
                itemHeight: 20,
                itemTextColor: '#999999',
                symbolSize: 12,
                symbolShape: 'circle',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemTextColor: '#000000'
                        }
                    }
                ]
            }
        ]}
      />

  );
  }
}
