import React from 'react'
import { useState,useCallback,useRef } from "react";
import {
  Header,
  Grid,
  Image,
  Segment,
  Container
} from "semantic-ui-react";
import './MainTitle.css'
import { animated, useSpring} from 'react-spring'
import { loadFull } from "tsparticles";

const HomepageHeading = ({ mobile }) => {
  const particlesInit = useCallback(async (engine: Engine) => {
          console.log(engine);
          // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
          // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
          // starting from v2 you can add only the features you need reducing the bundle size
          await loadFull(engine);
      }, []);

      const particlesLoaded = useCallback(async (container : ParticlesContainer | undefined) => {
          await console.log(container);
      }, []);
  return(

    <Segment basic className="header-container" style={{height: mobile ? '400px' : '500px',}}>
      <Grid style={{marginTop: mobile ? '1.5em' : '2em',}}>
      <Grid.Row style={{padding:"0em"}}>
        <Grid.Column >
          <Container >
            <Header
              as='h1'
              content='Pellicer Financial'
              style={{
                fontSize: mobile ? '4em' : '5em',
                fontWeight: 'heavy',
                fontFamily: "Urbanist",
                color: "#FFFFFF",
                marginBottom: 0,
                marginTop: mobile ? '1.5em' : '1.5em',
              }}
            />
            <Header
              as='h2'
              content='Powering market efficiency'
              style={{
                fontSize: mobile ? '1.5em' : '2em',
                fontWeight: 'normal',
                fontFamily: "Nunito",
                color: "#FFFFFF",
                marginTop: mobile ? '0.5em' : '1em',
              }}
            />
          </Container>
        </Grid.Column>
      </Grid.Row>
      </Grid>
    </Segment>
)
}


export function MainTitle ({mobile}) {

    return(
      <div >

    <Grid verticalAlign="middle" container style={{ margin: "0em 3em"}}>
    <Grid.Row columns={1}>


    <Grid.Column width ={12} textAlign="left" >
    <Header
      as='h1'
      content='Pellicer Financial'
      style={{
        fontSize: '5em',
        fontWeight: 'heavy',
        fontFamily: "Urbanist",
        color: "#FFFFFF",
        marginBottom: 0,
        marginTop: '3.5em',
        lineHeight: '1em'
      }}
    />
    <Header
      as='h2'
      content='Powering market efficiency'
      style={{
        fontSize: '2em',
        fontWeight: 'normal',
        fontFamily: "Nunito",
        color: "#FFFFFF",
        marginTop: '0.25em',
      }}
    />
    </Grid.Column>

    </Grid.Row>
    </Grid>

  </div>
  )
}
