import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import { Segment,
  Grid,
  Card,
  Container,
  Header,
  Message,
  Icon,
  Table,
  Input
} from 'semantic-ui-react';
import './DataTable.css'


export const DataTable = (props) => {

    function onTrigger (chartID) {
        console.log(chartID);
        this.props.pushItem(chartID);
    };

    const { chartName, chartMessage, chartID } = props;

    return (
      <div className="chart-segment">
      <Table basic='very' selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Trade Date <Input/></Table.HeaderCell>
            <Table.HeaderCell>Symbol <Input/></Table.HeaderCell>
            <Table.HeaderCell>Price</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>1/5/2021</Table.Cell>
            <Table.Cell>AAPL</Table.Cell>
            <Table.Cell>50.70</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>1/5/2021</Table.Cell>
            <Table.Cell>FB</Table.Cell>
            <Table.Cell>100.68</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>1/5/2021</Table.Cell>
            <Table.Cell>TSLA</Table.Cell>
            <Table.Cell>300.50</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      </div>
  );

};



export default DataTable;
