import { useState,useCallback } from "react";
import {
  Button,
  Container,
  Grid,
  Header,
  Segment,
  Icon,
  Image,
  List,
  Divider,
  Menu,
  Card,
  Sidebar
} from "semantic-ui-react";
import Particles from "react-particles";
import { loadFull } from "tsparticles";
import type { ParticlesContainer, Engine } from "tsparticles-engine";
import {particlesConfig,particlesConfig2,particlesConfig3} from "../../particles";

import { Parallax, ParallaxLayer } from '@react-spring/parallax'
import { InView } from 'react-intersection-observer'
import "./home.css"
import {Footer} from "../../containers/footer/footer"
import {TopNav} from "../../components/topNav/topNav"
import { createMedia } from '@artsy/fresnel'
import PropTypes from 'prop-types'
import { Media } from '../../App'
import { FrontGraphic } from "../../components/FrontGraphic/FrontGraphic";

const services = [
  { key: 0,
    header: 'Cost Efficiency Strategies',
    description: 'Customized trade routing strategies setup for your trading flow profile',
  },
  { key: 1,
    header: 'Demand Management',
    description: 'Manage IDB and floor broker flow routing in order to maximize rebates and lower cost',
  },
  { key: 2,
    header: 'Broker Performance',
    description: 'Measure the price levels your brokers are executing versus a benchmark',
  },


]



const HomepageHeading = ({ mobile }) => {
  const particlesInit = useCallback(async (engine: Engine) => {
          console.log(engine);
          // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
          // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
          // starting from v2 you can add only the features you need reducing the bundle size
          await loadFull(engine);
      }, []);

      const particlesLoaded = useCallback(async (container : ParticlesContainer | undefined) => {
          await console.log(container);
      }, []);
  return(

    <Segment basic className="header-container" style={{height: mobile ? '400px' : '500px',}}>
      <Grid style={{marginTop: mobile ? '1.5em' : '2em',}}>
      <Grid.Row style={{padding:"0em"}}>
        <Grid.Column >
          <Container >
            <Header
              as='h1'
              content='Pellicer Financial'
              style={{
                fontSize: mobile ? '4em' : '5em',
                fontWeight: 'heavy',
                fontFamily: "Urbanist",
                color: "#FFFFFF",
                marginBottom: 0,
                marginTop: mobile ? '1.5em' : '1.5em',
              }}
            />
            <Header
              as='h2'
              content='Powering market efficiency'
              style={{
                fontSize: mobile ? '1.5em' : '2em',
                fontWeight: 'normal',
                fontFamily: "Nunito",
                color: "#FFFFFF",
                marginTop: mobile ? '0.5em' : '1em',
              }}
            />
          </Container>
        </Grid.Column>
      </Grid.Row>
      </Grid>
    </Segment>
)
}

HomepageHeading.propTypes = {
  mobile: PropTypes.bool,
}

function DesktopContainer (props) {
  const [fixed, setFixed] = useState(false);
  const toggleFixedMenu = (inView) => setFixed(!inView)

  const children = props.children

  return (
      <Media greaterThan='mobile'>
        <InView onChange={toggleFixedMenu}>
            <TopNav fixed={fixed}/>
            <HomepageHeading />
        </InView>
        <Segment className="home-segment">
          <Grid container stackable verticalAlign='middle'>
          <Grid.Row>
            <Grid.Column verticalAlign='middle'>

              <Header as="h3" style={{ fontSize: "3em", fontFamily: "Nunito", }}>
                Decrease your execution costs,
                Increase trade transparency
              </Header>
              <p style={{ fontSize: "1.5em" }}>
                Pellicer’s proven processes and technology enable your desk to take control of trading costs, adding directly to your P&L
              </p>
              <List bulleted style={{ fontSize: "1.5em" }}>
                <List.Item>Manage exchange and brokerage costs using a holistic approach taileroed to your firm's trading style</List.Item>
                <List.Item>Leverage technology</List.Item>
                <List.Item>Over 10 years experience in market structure</List.Item>
              </List>
            </Grid.Column>
          </Grid.Row>
          </Grid>
        </Segment>
        <Segment className="home-segment-middle">
          <Grid container >
          <Grid.Row>
          <Grid.Column >
            <Container >
            <Card.Group centered>
            {services.map(services => {
                      return (
                        <Card
                        className='services-card'
                        key={services.key}
                        >
                          <Card.Content>
                            <Card.Header>
                            {services.header}
                            </Card.Header>
                            <Card.Description>
                            {services.description}
                            </Card.Description>
                          </Card.Content>
                        </Card>
                      );
            })}
            </Card.Group>
            </Container>
          </Grid.Column>
          </Grid.Row>
          </Grid>
        </Segment>
        <Segment className="home-segment">
          <Grid columns='equal' stackable>
            <Grid.Row textAlign='center'>

            </Grid.Row>
          </Grid>
        </Segment>
      </Media>
    )
}

DesktopContainer.propTypes = {
  children: PropTypes.node,
}

function MobileContainer (props) {
  const [sidebarOpened, setSidebarOpened] = useState(false);
  const handleSidebarHide = () => setSidebarOpened(false)
  const handleToggle = () => setSidebarOpened(true)

  return (
      <div>


          <Sidebar
            as={Menu}
            animation='overlay'
            direction='right'
            onHide={()=>setSidebarOpened(false)}
            vertical
            visible={sidebarOpened}
          >
            <Menu.Item as='a' active>
              Home
            </Menu.Item>
            <Menu.Item as='a'>Work</Menu.Item>
            <Menu.Item as='a'>Company</Menu.Item>
            <Menu.Item as='a'>Careers</Menu.Item>
            <Menu.Item as='a'>Log in</Menu.Item>
            <Menu.Item as='a'>Sign Up</Menu.Item>
          </Sidebar>

          <Sidebar.Pusher dimmed={sidebarOpened}>
          <TopNav mobile fixed={true} handleToggle={handleToggle}/>

          <FrontGraphic />
          </Sidebar.Pusher>

      </div>
    )
}

function MobileContainer2 (props) {
  const [sidebarOpened, setSidebarOpened] = useState(false);
  const handleSidebarHide = () => setSidebarOpened(false)
  const handleToggle = () => setSidebarOpened(true)

  return (
      <Media as={Sidebar.Pushable} at='mobile'>

        <Sidebar.Pushable>
          <Sidebar
            as={Menu}
            animation='overlay'
            direction='right'
            onHide={()=>setSidebarOpened(false)}
            vertical
            visible={sidebarOpened}
          >
            <Menu.Item as='a' active>
              Home
            </Menu.Item>
            <Menu.Item as='a'>Work</Menu.Item>
            <Menu.Item as='a'>Company</Menu.Item>
            <Menu.Item as='a'>Careers</Menu.Item>
            <Menu.Item as='a'>Log in</Menu.Item>
            <Menu.Item as='a'>Sign Up</Menu.Item>
          </Sidebar>

          <Sidebar.Pusher dimmed={sidebarOpened}>
          <TopNav mobile fixed={true} handleToggle={handleToggle}/>
          <HomepageHeading mobile />
              {props.children}
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </Media>
    )
}

MobileContainer.propTypes = {
  children: PropTypes.node,
}

const ResponsiveContainer = ({ children }) => (
  /* Heads up!
   * For large applications it may not be best option to put all page into these containers at
   * they will be rendered twice for SSR.
   */
   <div>
    <DesktopContainer>{children}</DesktopContainer>

    </div>
)

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
}

export function Home (props){

  return(
    <div>

    <Media greaterThan='mobile'>
      <DesktopContainer />
      <Footer/>
    </Media>
    <Media at='mobile'>
      <FrontGraphic />
    </Media>


    </div>
  )

}

Home.propTypes = {
  children: PropTypes.node,
}
