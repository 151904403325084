import React from 'react'
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  Button,
  Container,
  Header,
  Grid,
  Icon,
  List,
  Image,
  Segment,
  Card
} from "semantic-ui-react";
import './MainSegment.css'
import { Spring,animated, config, useSpring,useTransition} from 'react-spring'

const services = [
  { key: 0,
    header: 'Exchange Routing Strategies',
    description: 'Customized routing strategies configured for your trading flow profile. Costs are optimized without sacrificing execution quality.',
    icon:'dollar sign',
  },
  { key: 1,
    header: 'Demand Management',
    description: 'Manage IDB and floor broker flow routing in order to maximize rebates and lower cost',
    icon:'code branch',
  },
  { key: 2,
    header: 'Broker Attribution',
    description: 'Measure brokered execution levels versus a benchmark',
    icon:'chart bar',
  },


]

export function MainSegment (props) {
  
  const springProps = useSpring({ from: { x: props.active ? -500 : 0 }, to: { x: props.active ? 0 : -500 },opacity: props.active ? 1 : 0, delay:500, config: { frequency: 1, tension:	170,friction: 50 } })

  const springProps2 = useSpring({ from: { x: props.active ? 500 : 0 }, to: { x: props.active ? 0 : 500 },opacity: props.active ? 1 : 0, delay:500, config: { frequency: 1, tension:	170,friction: 50 } })
  return(

  <Grid container >
    <Grid.Row>
      <Grid.Column>
      <animated.div style={springProps}>
          <Header as="h2" className='segment-header'>
          Increase transparency,
          decrease execution costs
          </Header>
      </animated.div>


      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column>

          <animated.div style={springProps2} >
          <p className='segment-text'>
          Take control of trading costs with Pellicer’s proven technology and expertise in market structure
          </p>
          </animated.div>

      </Grid.Column>
    </Grid.Row>

  </Grid>

)
}

export function BottomSegment (props) {


  return(

  <div >
      {services.map(services => {
                return (
                  <Grid
                  container
                  className='services-card'
                  key={services.key}
                  >
                  <Grid.Row columns={2}>
                  <Grid.Column width={2}>
                  <Icon name={services.icon} size="big" inverted/>
                  </Grid.Column>
                  <Grid.Column width={14}>
                  <Grid.Row>
                      <Header className="services-card-header">
                      {services.header}

                      </Header>
                      </Grid.Row>
                      <Grid.Row>

                          <p className="services-card-text">
                          {services.description}
                          </p>

                      </Grid.Row>
                  </Grid.Column>
                  </Grid.Row>

                  </Grid>
                );
      })}

  </div>

)
}
