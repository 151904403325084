import { useState } from 'react';

import { Segment, Grid, Card, Container, Header, Message,Icon, Statistic, Button } from 'semantic-ui-react';
import './ActionCard.css'



export const ActionCard = (props) => {

  function onTrigger (chartID) {
        console.log(chartID);
        this.props.pushItem(chartID);
  };


    const { cardName, cardText, cardSubText, cardColor } = props;

    return (

      <div className={cardColor === 'dark' ? 'action-segment-dark' : 'action-segment-light' }>

      <Grid verticalAlign='middle' centered>
      <Grid.Row>
         <Grid.Column width={14}>
          <Header as='h2' >
            <Header.Content>
                {cardText}
                  <Header.Subheader>
                    {cardSubText}
                  </Header.Subheader>
             </Header.Content>
           </Header>
         </Grid.Column>
        <Grid.Column width={2} style={{textAlign:"right"}}>
        <Icon name='external alternate' link/>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row verticalAlign='bottom'>
        <Grid.Column >
        <Button floated='right'>
        Submit
        </Button>
        </Grid.Column>
      </Grid.Row>
      </Grid>

      </div>

  );

};
