import { useEffect, useState } from 'react';

import { Segment, Grid, Card, Container, Header, Message,Icon, Transition, Dimmer } from 'semantic-ui-react';
import './ChartCard.css'
import { BarChart, PieChart, LineChart, BumpChart, StreamChart } from "../../containers/Charts";



export const ChartCard = (props) => {

  const [showDetails, setShowDetails] = useState(false)
  const [id, setID] = useState('')
  const [value, setValue] = useState('')

  useEffect(() => {

    console.log(props)
  },[]);

  function onTrigger (chartID, chartName) {
        console.log(chartID);
        props.pushItem(chartID, chartName);
    };

  const pushDetails = (id, value) => {
        setShowDetails(true);
        setID(id);
        setValue(value);
        console.log(id,value);
      };

  const hideDetails = () => this.setState({ showDetails: false })



  function chartSwitch(chartType, chartData) {
    switch(chartType) {
      case 'bar':
        return (
          <BarChart
            data={chartData}
            keys={[ 'hot dog', 'burger', 'sandwich', 'kebab', 'fries', 'donut' ]}
            index={"country"}
            groupMode={"stacked"}
            layout={"vertical"}
            pushDetails = {pushDetails}
          />
        );
      case 'pie':
        return (
          <PieChart
            data={chartData}
            pushDetails = {pushDetails}
          />
        );
      case 'line':
        return (
          <LineChart
            data={chartData}
            pushDetails = {pushDetails}
          />
        );
      case 'bump':
        return (
          <BumpChart
            data={chartData}
            pushDetails = {pushDetails}
          />
        );
      case 'stream':
        return (
          <StreamChart
            data={chartData}
            pushDetails = {pushDetails}
          />
        );
      default:
        return (
          <BarChart
            data={chartData}
            keys={[ 'hot dog', 'burger', 'sandwich', 'kebab', 'fries', 'donut' ]}
            index={"country"}
            groupMode={"stacked"}
            layout={"vertical"}
          />
        );
    }
  }
    const { chartName, chartMessage, chartID, chartType, chartData } = {...props};


    return (
      <Dimmer.Dimmable as={Segment} className='chart-segment' dimmed={showDetails} blurring>

      <Grid>
      <Grid.Row>
         <Grid.Column width={14}>
         <Header as='h5'>
         
         <Header.Content>
             {chartName}
             <Header.Subheader>2023</Header.Subheader>
         </Header.Content>
         </Header>
         </Grid.Column>
        <Grid.Column width={2} style={{textAlign:"right"}}>
      <Icon name='external alternate' link onClick={()=>onTrigger(chartID, chartName)}/>
      </Grid.Column>
      </Grid.Row>
      </Grid>

      <div className="chart-container">
        {chartSwitch(chartType, chartData)}
      </div>

      <Dimmer active={showDetails} onClickOutside={()=>setShowDetails(false)} >

      <Grid>
      <Grid.Row columns={2}>
      <Grid.Column>
        <Header as='h2' inverted>{id}</Header>
        </Grid.Column>
        <Grid.Column>
        <Header as='h2' inverted>{value}</Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
      <Grid.Column>
        <Header as='h4' inverted>
          <Header.Content>
             Strategies applied:
             <Header.Subheader>Route firm voice</Header.Subheader>
          </Header.Content>
        </Header>
        </Grid.Column>
      </Grid.Row>
      </Grid>


      </Dimmer>




      </Dimmer.Dimmable>

  );

};
