export const BarData = (
[
  {
    "month": "Jan",
    "AMEX": 75,
    "hot dogColor": "hsl(12, 70%, 50%)",
    "ARCA": 38,
    "burgerColor": "hsl(100, 70%, 50%)",
    "PHLX": 126,
    "sandwichColor": "hsl(63, 70%, 50%)",
    "CBOE": 20,
    "kebabColor": "hsl(322, 70%, 50%)",
  },
  {
    "month": "Feb",
    "AMEX": 75,
    "hot dogColor": "hsl(12, 70%, 50%)",
    "ARCA": 38,
    "burgerColor": "hsl(100, 70%, 50%)",
    "PHLX": 126,
    "sandwichColor": "hsl(63, 70%, 50%)",
    "CBOE": 20,
    "kebabColor": "hsl(322, 70%, 50%)",
  },

]
)

export const PieData = (
[
  {
    "id": "rust",
    "label": "rust",
    "value": 411,
    "color": "hsl(111, 70%, 50%)"
  },
  {
    "id": "php",
    "label": "php",
    "value": 391,
    "color": "hsl(30, 70%, 50%)"
  },
  {
    "id": "go",
    "label": "go",
    "value": 576,
    "color": "hsl(257, 70%, 50%)"
  },
  {
    "id": "css",
    "label": "css",
    "value": 346,
    "color": "hsl(148, 70%, 50%)"
  },
  {
    "id": "javascript",
    "label": "javascript",
    "value": 448,
    "color": "hsl(170, 70%, 50%)"
  }
]
)

export const LineData = (
[
  {
    "id": "japan",
    "color": "hsl(34, 70%, 50%)",
    "data": [
      {
        "x": "plane",
        "y": 112
      },
      {
        "x": "helicopter",
        "y": 93
      },
      {
        "x": "boat",
        "y": 251
      },
      {
        "x": "train",
        "y": 198
      },
      {
        "x": "subway",
        "y": 140
      },
      {
        "x": "bus",
        "y": 99
      },
      {
        "x": "car",
        "y": 127
      },
      {
        "x": "moto",
        "y": 66
      },
      {
        "x": "bicycle",
        "y": 284
      },
      {
        "x": "horse",
        "y": 195
      },
      {
        "x": "skateboard",
        "y": 59
      },
      {
        "x": "others",
        "y": 100
      }
    ]
  },
  {
    "id": "france",
    "color": "hsl(139, 70%, 50%)",
    "data": [
      {
        "x": "plane",
        "y": 247
      },
      {
        "x": "helicopter",
        "y": 215
      },
      {
        "x": "boat",
        "y": 86
      },
      {
        "x": "train",
        "y": 45
      },
      {
        "x": "subway",
        "y": 296
      },
      {
        "x": "bus",
        "y": 67
      },
      {
        "x": "car",
        "y": 270
      },
      {
        "x": "moto",
        "y": 223
      },
      {
        "x": "bicycle",
        "y": 43
      },
      {
        "x": "horse",
        "y": 215
      },
      {
        "x": "skateboard",
        "y": 27
      },
      {
        "x": "others",
        "y": 197
      }
    ]
  },
  {
    "id": "us",
    "color": "hsl(338, 70%, 50%)",
    "data": [
      {
        "x": "plane",
        "y": 114
      },
      {
        "x": "helicopter",
        "y": 234
      },
      {
        "x": "boat",
        "y": 136
      },
      {
        "x": "train",
        "y": 63
      },
      {
        "x": "subway",
        "y": 299
      },
      {
        "x": "bus",
        "y": 94
      },
      {
        "x": "car",
        "y": 245
      },
      {
        "x": "moto",
        "y": 278
      },
      {
        "x": "bicycle",
        "y": 48
      },
      {
        "x": "horse",
        "y": 264
      },
      {
        "x": "skateboard",
        "y": 191
      },
      {
        "x": "others",
        "y": 142
      }
    ]
  },
  {
    "id": "germany",
    "color": "hsl(23, 70%, 50%)",
    "data": [
      {
        "x": "plane",
        "y": 169
      },
      {
        "x": "helicopter",
        "y": 145
      },
      {
        "x": "boat",
        "y": 72
      },
      {
        "x": "train",
        "y": 49
      },
      {
        "x": "subway",
        "y": 81
      },
      {
        "x": "bus",
        "y": 102
      },
      {
        "x": "car",
        "y": 57
      },
      {
        "x": "moto",
        "y": 56
      },
      {
        "x": "bicycle",
        "y": 233
      },
      {
        "x": "horse",
        "y": 173
      },
      {
        "x": "skateboard",
        "y": 291
      },
      {
        "x": "others",
        "y": 244
      }
    ]
  },
  {
    "id": "norway",
    "color": "hsl(282, 70%, 50%)",
    "data": [
      {
        "x": "plane",
        "y": 76
      },
      {
        "x": "helicopter",
        "y": 295
      },
      {
        "x": "boat",
        "y": 251
      },
      {
        "x": "train",
        "y": 70
      },
      {
        "x": "subway",
        "y": 60
      },
      {
        "x": "bus",
        "y": 36
      },
      {
        "x": "car",
        "y": 14
      },
      {
        "x": "moto",
        "y": 4
      },
      {
        "x": "bicycle",
        "y": 163
      },
      {
        "x": "horse",
        "y": 287
      },
      {
        "x": "skateboard",
        "y": 246
      },
      {
        "x": "others",
        "y": 9
      }
    ]
  }
]
)

export const BumpData = (

[
  {
    "id": "JavaScript",
    "data": [
      {
        "x": 2000,
        "y": 11
      },
      {
        "x": 2001,
        "y": 13
      },
      {
        "x": 2002,
        "y": 29
      },
      {
        "x": 2003,
        "y": 30
      },
      {
        "x": 2004,
        "y": 17
      },
      {
        "x": 2005,
        "y": 12
      }
    ]
  },
  {
    "id": "ReasonML",
    "data": [
      {
        "x": 2000,
        "y": 23
      },
      {
        "x": 2001,
        "y": 29
      },
      {
        "x": 2002,
        "y": 27
      },
      {
        "x": 2003,
        "y": 18
      },
      {
        "x": 2004,
        "y": 25
      },
      {
        "x": 2005,
        "y": 12
      }
    ]
  },
  {
    "id": "TypeScript",
    "data": [
      {
        "x": 2000,
        "y": 21
      },
      {
        "x": 2001,
        "y": 22
      },
      {
        "x": 2002,
        "y": 10
      },
      {
        "x": 2003,
        "y": 16
      },
      {
        "x": 2004,
        "y": 24
      },
      {
        "x": 2005,
        "y": 13
      }
    ]
  },
  {
    "id": "Elm",
    "data": [
      {
        "x": 2000,
        "y": 19
      },
      {
        "x": 2001,
        "y": 21
      },
      {
        "x": 2002,
        "y": 19
      },
      {
        "x": 2003,
        "y": 26
      },
      {
        "x": 2004,
        "y": 12
      },
      {
        "x": 2005,
        "y": 10
      }
    ]
  },
  {
    "id": "CoffeeScript",
    "data": [
      {
        "x": 2000,
        "y": 25
      },
      {
        "x": 2001,
        "y": 10
      },
      {
        "x": 2002,
        "y": 24
      },
      {
        "x": 2003,
        "y": 28
      },
      {
        "x": 2004,
        "y": 13
      },
      {
        "x": 2005,
        "y": 13
      }
    ]
  }
]
)

export const StreamData = (

[
  {
    "Raoul": 57,
    "Josiane": 80,
    "Marcel": 49,
    "René": 200,
    "Paul": 153,
    "Jacques": 81
  },
  {
    "Raoul": 152,
    "Josiane": 145,
    "Marcel": 117,
    "René": 72,
    "Paul": 108,
    "Jacques": 14
  },
  {
    "Raoul": 112,
    "Josiane": 158,
    "Marcel": 26,
    "René": 113,
    "Paul": 167,
    "Jacques": 187
  },
  {
    "Raoul": 123,
    "Josiane": 18,
    "Marcel": 142,
    "René": 143,
    "Paul": 181,
    "Jacques": 116
  },
  {
    "Raoul": 34,
    "Josiane": 151,
    "Marcel": 140,
    "René": 127,
    "Paul": 189,
    "Jacques": 102
  },
  {
    "Raoul": 71,
    "Josiane": 23,
    "Marcel": 173,
    "René": 145,
    "Paul": 84,
    "Jacques": 150
  },
  {
    "Raoul": 166,
    "Josiane": 102,
    "Marcel": 153,
    "René": 41,
    "Paul": 144,
    "Jacques": 143
  },
  {
    "Raoul": 20,
    "Josiane": 191,
    "Marcel": 149,
    "René": 31,
    "Paul": 165,
    "Jacques": 192
  },
  {
    "Raoul": 175,
    "Josiane": 196,
    "Marcel": 90,
    "René": 112,
    "Paul": 116,
    "Jacques": 52
  }
]
)
