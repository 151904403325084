
export const particlesConfig3 = {
  "detectRetina": false,
  "fpsLimit": 60,
  "interactivity": {
    "detectsOn": "canvas",
    "events": {
      "onHover": {
        "enable": true,
        "mode": "bubble",
      },
      "resize":  true
      }
    },
    "modes": {
      "bubble": {
        "distance": 40,
        "duration": 2,
        "opacity": 8,
        "size": 6,
        "speed": 3
      }
    },
  "particles": {
    "color": {
      "value": "#ffffff",
      "animation": {
          "enable": true,
          "speed": 20,
          "sync": true
      }
    },
    "lineLinked": {
      "blink": false,
      "color": "random",
      "consent": false,
      "distance": 30,
      "enable": true,
      "opacity": 0.3,
      "width": 0.5
    },
    "move": {
      "attract": {
        "enable": false,
        "rotate": {
          "x": 600,
          "y": 1200
        }
      },
      "bounce": false,
      "direction": "none",
      "enable": true,
      "outMode" : "bounce",
      "random": true,
      "speed": 1,
      "straight": false,
    },
    "number": {
      "density": {
        "enable": false,
        "area": 2000,
      },
      "limit": 0,
      "value": 200
    },
    "opacity": {
      "animation": {
        "enable": true,
        "speed": 2,
        "sync": false,
        "minimumValue": 0.05
      },
      "random": false,
      "value": 1
    },
    "shape": {
      "type": "circle"
    },
    "size": {
      "animation": {
        "enable": false,
        "speed": 40,
        "sync": false,
        "minimumValue": 0.1
      },
      "random": true,
      "value": 1
    },

  "polygon": {
    "enable":true ,
    "draw": {
      "enable": true,
      "lineColor": "rgba(255,255,255,0.2)",
      "lineWidth": 0.3
    },
    "move": {
      "radius": 10,
    },
    "inlineArrangement": "equidistant",
    "scale": 0.5,
    "type": "inline",
    "url": "https://particles.js.org/images/smalldeer.svg"
  }
}};


export const particlesConfig = (
  {

    "fullScreen": {
      "enable": false,
      "zIndex": 0
    },
    "detectRetina": false,
    "fpsLimit": 30,
    "interactivity": {
      "events": {
        "onClick": {
          "mode": "push"
        },
        "onDiv": {
          "selectors": "#repulse-div",
          "mode": "repulse"
        },
        "onHover": {
          "enable": true,
          "mode": "bubble"
        }
      },
      "modes": {
        "bubble": {
          "distance": 40,
          "duration": 2,
          "opacity": 8,
          "size": 6
        },
        "grab": {
          "distance": 400
        },
        "slow": {
          "factor": 1,
          "radius": 0
        }
      }
    },
    "particles": {
      "color": {
        "value": "#ffffff"
      },
      "links": {
        "color": {
          "value": "#ffffff"
        },
        "distance": 30,
        "enable": true,
        "opacity": 0.4
      },
      "move": {
        "attract": {
          "rotate": {
            "x": 600,
            "y": 1200
          }
        },
        "enable": true,
        "outModes": {
          "default": "bounce",
          "bottom": "bounce",
          "left": "bounce",
          "right": "bounce",
          "top": "bounce"
        },
        "speed": 1
      },
      "number": {
        "density": {
          "area": 2000
        },
        "value": 200
      },
      "opacity": {
        "value": 0.4,
        "animation": {
          "enable": true,
          "minimumValue": 0.05
        }
      },
      "size": {
        "random": {
          "enable": true
        },
        "value": 1,
        "animation": {
          "minimumValue": 0.1,
          "speed": 40
        }
      }
    },
    "polygon": {
      "draw": {
        "enable": true,
        "stroke": {
          "color": {
            "value": "rgba(255,255,255,0.2)"
          },
          "width": 0.5,
          "opacity": 0.2
        }
      },
      "enable": true,
      "inline": {
        "arrangement": "equidistant"
      },
      "move": {
        "radius": 10,
        "type": "path"
      },
      "scale": 0.5,
      "type": "inline",
      "url": "https://particles.js.org/images/smalldeer.svg"
    }
  }
)

export const particlesConfig2 = (
{
          fullScreen: {
            enable: false,
            zIndex: 0
          },
          background: {
              color: {
                  value: "#03041A",
              },
          },
          fpsLimit: 60,
          interactivity: {
            events: {
              onClick: {
                mode: "push"
              },
              onDiv: {
                selectors: "#repulse-div",
                mode: "repulse"
              },
              onHover: {
                enable: true,
                mode: "bubble"
              }
            },
            modes: {
              bubble: {
                distance: 40,
                duration: 2,
                opacity: 8,
                size: 6
              },
              grab: {
                distance: 400
              },
              slow: {
                factor: 1,
                radius: 0
              }
            }
          },
          particles: {
              color: {
                  value: "#ffffff",
              },
              links: {
                color: {
                  value: "#ffffff"
                },
                distance: 30,
                enable: true,
                opacity: 0.4
              },
              move: {
                attract: {
                  rotate: {
                    x: 600,
                    y: 1200
                  }
                },
                enable: true,
                outModes: {
                  default: "bounce",
                  bottom: "bounce",
                  left: "bounce",
                  right: "bounce",
                  top: "bounce"
                },
                speed: 1
              },
              number: {
                density: {
                  area: 2000
                },
                value: 200
              },
              opacity: {
                value: 0.4,
                animation: {
                  enable: true,
                  minimumValue: 0.05
                }
              },
              size: {
                random: {
                  enable: true
                },
                value: 1,
                animation: {
                  minimumValue: 0.1,
                  speed: 40
                }
              },
          },
          polygon: {
            draw: {
              enable: true,
              lineColor: "rgba(255,255,255,0.2)",
              lineWidth: 0.5,
              stroke: {
                color: {
                  value: "rgba(255,255,255,0.2)"
                },
                width: 1,
                opacity: 0.2
              }
            },
            enable: true,
            inline: {
              arrangement: "equidistant"
            },
            move: {
              radius: 10,
              type: "path"
            },
            inline: {
              arrangement: "equidistant"
            },
            scale: 0.5,
            type: "inline",
            url: "https://particles.js.org/images/smalldeer.svg"
          },
          detectRetina: false,
      }
)
